import { Link } from 'react-router-dom';

function Resume() {
    return (
        <>
            {/* ======= Resume Section ======= */}
            <section id="resume" className="resume mt-5">
                <div className="container">

                <div className="section-title">
                    <h2>Резюме</h2>
                    {/* <p>Подлинное счастье может дать только обучение. И новое, пусть и не большое, расширение границ познания мира.</p> */}
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="fade-up">
                    <h3 className="resume-title">Общие данные</h3>
                    <div className="resume-item pb-0">
                        <h4>Сергей Михайлов</h4>
                        <p><em>Разработчик web-приложений (сайтов), десктопных приложений, мобильных приложений. Немножко дизайнер.</em></p>
                        <ul>
                        <li>Республика Казахстан, город Павлодар</li>
                        <li>+77088210530</li>
                        <li>mihas-ox7-74@mail.ru</li>
                        </ul>
                    </div>

                    <h3 className="resume-title">Образование</h3>
                    <div className="resume-item">
                        <h4>Высшее. Финансы. Финансовый менеджмент.</h4>
                        <h5>2004 - 2009</h5>
                        <p><em>Павлодарский государственный университет им.С.Торайгырова</em></p>
                        <p>Павлодарский индустриальный институт (ПИИ) был образован Постановлением Совета Министров СССР от 20 сентября 1960 года № 1009. В 1992 году ПИИ преобразован в Казахский государственный технический университет. В 1994 году вуз был преобразован в Павлодарский государственный университет, а в 1996 году Постановлением Кабинета Министров Республики Казахстан был переименован в Павлодарский государственный университет имени С. Торайгырова.</p>
                    </div>
                    <div className="resume-item">
                        <h4>Математическая школа</h4>
                        <h5>1981 - 1991</h5>
                        <p><em>Общеобразовательная школа №3, созданная в 1957 году и преобразованная в 1997 году в гимназию, в итоге длительного пути своего развития в 2005 году получила  статус гимназии для одаренных детей. </em></p>
                        <p>Закончил физико-математическую школу в Советском Союзе, чему несказанно рад. Участвовал в олимпиадах по физике и математике.</p>
                    </div>
                    </div>
                    <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                    <h3 className="resume-title">Программирование</h3>
                    <div className="resume-item">
                        <h4>FULL STACK РАЗРАБОТЧИК</h4>
                        <h5>2017 - 2019</h5>
                        <p><em>ТОО «Компания ФИНИСТ», Павлодар </em></p>
                        <ul>
                        <li>В этой компании я проработал два года на должности инженер-программист.</li>
                        <li>Изучил все основы работы серверной и клиентской части приложений.</li>
                        <li>Участвовал в поддержке 60+ сайтов государственных компаний. Участвовал в разработке больших web-приложений на php-фреймворке Kohana, в разработке мобильного приложения на javascript (Apache Cordova). И многое другое.</li>
                        <li>В этой компании я научился всем главным основополагающим концепциям, необходимым для разработки сайтов, мобильных приложений и десктопных приложений.</li>
                        </ul>
                    </div>
                    <div className="resume-item">
                        <h4>FULL STACK РАЗРАБОТЧИК</h4>
                        <h5>2019 - 2021</h5>
                        <p><em>Небольшие проекты в разных компаниях, Павлодар</em></p>
                        <ul>
                        <li>Разработал несколько сайтов на cms WordPress.</li>
                        <li>Разработал админ-панель на фреймворке Vue Js. Компания предоставила готовый API.</li>
                        <li>Участвовал в небольших проектах как frontend-разработчик.</li>
                        </ul>
                    </div>
                    <div className="resume-item">
                        <h4>FULL STACK РАЗРАБОТЧИК</h4>
                        <h5>2021 - 2023</h5>
                        <p><em>Компания "ТОО Склад информационных технологий", Павлодар</em></p>
                        <ul>
                        <li>В этой компании мы разработали большой портал - "ПОРТАЛ ПРОХОЖДЕНИЯ ВЕДОМСТВЕННОЙ ЭКСПЕРТИЗЫ" департамента государственного учета отдела ведомственной экспертизы. Это web-приложение на фреймворе Laravel, в котором реализован самый разнообразный функционал, который возможен для цифровизации документооборота.</li>
                        <li>В этом приложении реализованы: создание PDF-документов, выгрузка данных в EXCEL, электронно-цифровые подписи, сложная схема документооборота и многое другое.</li>
                        <li>Инструкция для клиентов - <Link to="https://www.youtube.com/watch?v=FQ2R48FJicg" target="_blank">https://www.youtube.com/watch?v=FQ2R48FJicg</Link></li>
                        <li><Link to="http://uso-saraptama.kad.org.kz" target="_blank">http://uso-saraptama.kad.org.kz</Link></li>
                        </ul>
                    </div>
                    <div className="resume-item">
                        <h4>FULL STACK РАЗРАБОТЧИК</h4>
                        <h5>Апрель 2023 - по настоящее время</h5>
                        <p><em>Небольшие проекты, самообразование, Павлодар</em></p>
                        <ul>
                        <li>Занимался самообразованием (C#, Python, React, Vue, Go и другое). UMI CMS - начальные знания.</li>
                        <li>Познакомился с интеграцией amoCRM (начальные знания).</li>
                        <li>Познакомился с платформой lsFusion (начальные знания).</li>
                        </ul>
                    </div>
                    </div>
                </div>

                </div>
            </section>
            {/* End Resume Section */}
        </>
    );
}

export default Resume;