import Technologies from "../components/Technologies";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function About() {
    return (
        <>
            {/* Skills Section ======= */}
            <section id="skills" className="skills mt-5">
                <div className="container" data-aos="fade-up">
                    <div className="section-title">
                        <h2>Личные данные</h2>
                        <p>Подлинное счастье может дать только обучение. И новое, пусть и не большое, расширение границ познания мира.</p>
                    </div>
                    <div className="row">
                        <div className="col-lg-6 d-flex align-items-center" data-aos="fade-right" data-aos-delay="100">
                            <img src="assets/img/SM2.605d2c8a.png" className="img-fluid" alt="" />
                        </div>
                        <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left" data-aos-delay="100">
                            <h3>Skills</h3>
                            <p className="fst-italic">
                            ПРОСТО ДЕЛАЙ ТО, ЧТО ЛЮБИШЬ ДЕЛАТЬ!
                            </p>

                            <div className="skills-content">

                                <div className="progress">
                                    <span className="skill">HTML/CSS <i className="val">99%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="99" aria-valuemin="0" aria-valuemax="100" style={{width: '99%'}}></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">Laravel <i className="val">80%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{width: '80%'}}></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">React <i className="val">50%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '50%'}}></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">Vue <i className="val">50%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '50%'}}></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">Angular <i className="val">50%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '50%'}}></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">WordPress <i className="val">50%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '50%'}}></div>
                                    </div>
                                </div>

                                <div className="progress">
                                    <span className="skill">Photoshop <i className="val">50%</i></span>
                                    <div className="progress-bar-wrap">
                                    <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{width: '50%'}}></div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </section>
            {/* End Skills Section */}

            {/* ======= Testimonials Section ======= */}
            <section className="p-3">
                <div className="container">

                    <div className="row">
                        <div className="col-sm-2"></div>
                        <div className="col-sm-8">
                            <OwlCarousel 
                            className='owl-theme' 
                            loop 
                            items="1"
                            autoplay
                            margin={10} 
                            >
                                <div className='owl-item-box1'>
                                    <div className="testimonial-wrap">
                                        <div className="testimonial">
                                            <blockquote>
                                            <p>Мы – люди, и наша судьба, наше предназначение – учиться ради открытия все новых и новых непостижимых миров.</p>
                                            </blockquote>
                                            <p>&mdash; Карлос Кастанеда</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='owl-item-box1'>
                                    <div className="testimonial-wrap">
                                        <div className="testimonial">
                                            <blockquote>
                                            <p>Каждый имеет достаточно личной силы для чего угодно. В случае воина фокус состоит в том, чтобы отвернуть личную силу от своих слабостей и направить её к своей цели воина.</p>
                                            </blockquote>
                                            <p>&mdash; Карлос Кастанеда</p>
                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                        </div>
                        <div className="col-sm-2"></div>
                    </div>

                </div>
            </section>
            {/* End Testimonials Section */}


            <Technologies />
            
        </>
    );
}

export default About;